import React, { useState } from "react"
import * as THREE from 'three'
import { Suspense } from 'react'
import { Canvas, useLoader } from '@react-three/fiber'
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader'
import { ARContent, Project, PointCloudPlace, Poi, EditorStorage, ARAnchorType } from "../../data"
import { OrbitControls, Splat } from '@react-three/drei'
import { connect } from "react-redux"
import { Dispatch } from 'redux'
import { changeSelectedFloor, changeSelectedPoi, updateInitialPosition, updateError, toggleMapOverlay } from 'store/editor/actions'
import { ControlMode, Controls } from '../common/TransformControl/TransformControl'
import { ArContentModel, MapModel } from "../common/ArContentModel/ArContentModel"
import { ApplicationState } from "store/data"
import { EditorState, SelectedObjectType } from "store/editor/types"
import { UsageTip } from "../common/UsageTip/UsageTip"
import { Button, FormGroup, Input, Popover, Spinner } from "reactstrap"
import './pointCloudEditor.scss'
import { FloorSelector } from "./FloorSelector/FloorSelector"
import { useFloor, useStaticMap } from "./pointCloudHook"
import { ErrorBoundary } from "react-error-boundary"
import { EditorError, EditorErrorType } from "pages/Projects/types"
import { Tracking } from "../common/Tracking/Tracking"
import { uploadDistance } from "constants/const"
import { isArContentSelected, isPoiSelected } from "../utils"
import { useObjectSelect } from "../groupHook"


const scale = 1 // must be scale 1:1

const PoiModel = (poi: Poi, onClick: (objectId: string) => void) => {
    return <mesh
        key={poi._id}
        position={[poi.position.x, poi.position.y, poi.position.z]}
        name={`poi-${poi._id}`}
        onClick={(e) => {
            e.stopPropagation()
            onClick(`poi-${poi._id}`)
        }}
    >
        <octahedronGeometry args={[1, 0]} />
        <meshStandardMaterial color={'red'} transparent={true} opacity={0.3} alphaTest={0.3} />
    </mesh>
}

const isPoiName = (name: string) => name.startsWith("poi-")

const filterArContentToDisplay = (arContents: ARContent[], errContentList: Set<String>, editorState: EditorState, pointCloud: PointCloudPlace) => {
    const selectedFloor = editorState.selectedFloor
    const arContentToDisplay = arContents.filter(arContent => {
        if (errContentList.has(arContent._id || "")) return false
        const isPointCloudContent = arContent.isIndoorPointCloud && arContent.indoorPointCloudRefId === pointCloud._id
        if (selectedFloor) {
            return isPointCloudContent &&
                (
                    selectedFloor._id === arContent.floorId ||
                    (editorState.selectedObjectType === SelectedObjectType.ArContent && isArContentSelected(arContent, editorState)) ||
                    (!arContent.floorId && editorState.selectedFloor?.isDefault)
                )
        }
        return isPointCloudContent
    })
    return arContentToDisplay
}

const filterPoiToDisplay = (pois: Poi[], editorState: EditorState): Poi[] => {
    const selectedFloor = editorState.selectedFloor
    const poisToDisplay = pois.filter(poi => {
        if (selectedFloor) {
            return selectedFloor._id === poi.floorId ||
                (editorState.selectedObjectType === SelectedObjectType.Poi && isPoiSelected(poi, editorState)) ||
                (!poi.floorId && editorState.selectedFloor?.isDefault)
        }
        return true
    })
    return poisToDisplay
}

interface OwnProps {
    project: Project
    arContents: ARContent[]
    pois: Poi[]
    pointCloud: PointCloudPlace
    onArContentUpdate: (index: number, object: ARContent) => void
    onArContentStateUpdate: (index: number, object: ARContent) => void
    onPoiUpdate: (index: number, object: Poi) => void,
    onPoiStateUpdate: (index: number, object: Poi) => void,
    controlMode: ControlMode
}

interface DispatchProps {
    dispatch: Dispatch
}

interface StateProps {
    editorState: EditorState
}


type PointCloudEditorProps = OwnProps & StateProps & DispatchProps

const PointCloudOptions = ({
    place,
    onColorModeToggle,
    colorModeEnabled,
    onMapOverlayToggle,
    mapOverlayEnabled,
}: {
    place: PointCloudPlace
    onColorModeToggle: (val: boolean) => void
    colorModeEnabled: boolean
    onMapOverlayToggle: (val: boolean) => void
    mapOverlayEnabled: boolean
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const toggleOpen = () => {
        setIsOpen(!isOpen)
    }
    const pointCloudOptionId = "pointcloud-option"
    if (!place.originCoordinate && !place.publicSplatFile) return null

    return <>
        <Button id={pointCloudOptionId} data-testid="place-option-toggle" onClick={toggleOpen} color="light" className={`pointCloudToggleContainer p-2 rounded me-2`}>
            <h6 className="mb-0">Display Options <i className={`bi bi-chevron-${isOpen ? "up" : "down"}`} /></h6>
        </Button>
        <Popover
            isOpen={isOpen}
            toggle={toggleOpen}
            target={pointCloudOptionId}
            placement="bottom"
            popperClassName='bg-light border-light rounded'
            offset={[-27, 8]}
            hideArrow
        >
            <div className="pointCloudTPopoverContainer p-2">
                {
                    place.publicSplatFile && <div className="d-flex justify-content-between mb-1">
                        <h6 className="mb-0">Toggle Color Mode</h6>
                        <FormGroup switch className="mb-0">
                            <Input data-testid="color-mode-toggle-switch" type="switch" role="switch" checked={colorModeEnabled} onChange={(e) => {
                                onColorModeToggle(!colorModeEnabled)
                            }} />
                        </FormGroup>
                    </div>
                }
                {
                    place.originCoordinate && <div className="d-flex justify-content-between">
                        <h6 className="mb-0">Show Map Overlay</h6>
                        <FormGroup switch className="mb-0">
                            <Input data-testid="map-overlay-toggle-switch" type="switch" role="switch" checked={mapOverlayEnabled} onChange={(e) => {
                                onMapOverlayToggle(!mapOverlayEnabled)
                            }} />
                        </FormGroup>
                    </div>
                }
            </div>
        </Popover>
    </>
}

// TODO: Support Multi Select
const getSelectedSceneObjectId = (editorState: EditorState, arContents: ARContent[]): string => {
    const firstObjectId = editorState.selectedObjects[0]?.id
    if (
        editorState.selectedObjectType === SelectedObjectType.ArContent &&
        arContents.findIndex(arContent => arContent?._id === firstObjectId) !== -1
    ) {
        return firstObjectId
    }
    if (editorState.selectedObjectType === SelectedObjectType.Poi) {
        return `poi-${firstObjectId}`
    }
    return ""
}

const PointCloudEditorComponent = ({
    project,
    arContents,
    pois,
    pointCloud,
    dispatch,
    onArContentUpdate,
    onArContentStateUpdate,
    editorState,
    controlMode,
    onPoiUpdate,
    onPoiStateUpdate,
}: PointCloudEditorProps) => {
    const [enableClick, setEnableClick] = useState(true);
    const [splatMode, setSplatMode] = useState(false);
    const [errContentList, setErrContentList] = useState<Set<String>>(new Set<string>())
    const { floors } = useFloor(pointCloud._id, dispatch)
    const [shouldGetPosition, setShouldGetPosition] = useState(true)
    const [pointCloudBoundingBox, setPointCloudBoundingBox] = useState<THREE.Box3>()
    const { scale: mapScale, image: mapImage } = useStaticMap(pointCloud, pointCloudBoundingBox, dispatch)
    const {
        group,
        updateGroupFromArContent,
        addObjectToSelect,
        selectObject,
        clearSelection,
    } = useObjectSelect(editorState, dispatch, arContents)
    const selectedFloor = editorState.selectedFloor
    const selectedSceneObjectId = getSelectedSceneObjectId(editorState, arContents)
    const editorStorage: EditorStorage = JSON.parse(localStorage.getItem("graffity-editor-storage") || "{}");
    const isLastOpenedPointCloud = editorStorage.lastOpenedAnchorType === ARAnchorType.WorldAnchor &&
        editorStorage.lastOpenedObjectId === pointCloud._id
    const latestCameraData = editorStorage.latestCameraData

    const handleFloorSelect = (floorId?: string) => {
        const newFloor = floors.find(floor => floor._id === floorId)
        dispatch(changeSelectedFloor(newFloor))
    }

    const PointCloud = () => {
        const upperPlane = new THREE.Plane(new THREE.Vector3(0, -1, 0), selectedFloor?.yMax ? selectedFloor.yMax : Infinity)
        const lowerPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0), selectedFloor?.yMin ? -selectedFloor.yMin : -Infinity)
        const result = useLoader(PLYLoader, pointCloud.publicPointCloudFile)
        const material = new THREE.PointsMaterial({
            vertexColors: true,
            size: 0.05,
            clipShadows: true,
            clippingPlanes: [upperPlane, lowerPlane]
        });
        const point = new THREE.Points(result, material);
        point.scale.x *= scale
        point.scale.y *= scale
        point.scale.z *= scale
        result.computeBoundingBox()
        setPointCloudBoundingBox(result.boundingBox!)
        return <primitive object={point} />
    };

    const handleCameraPositionChange = (camera: THREE.PerspectiveCamera) => {
        const projectionVector = new THREE.Vector3()
        camera.getWorldDirection(projectionVector)
        let projectedPosition: THREE.Vector3 | undefined = undefined

        if (pointCloudBoundingBox) {
            const cameraRay = new THREE.Ray(camera.position, projectionVector)
            const floorPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0), selectedFloor?.yMin ? -selectedFloor.yMin : 0)
            const intersectPoint = new THREE.Vector3()
            const res = cameraRay.intersectPlane(floorPlane, intersectPoint)
            if (res !== null && pointCloudBoundingBox.distanceToPoint(intersectPoint) === 0) {
                projectedPosition = intersectPoint
            }
        }
        if (projectedPosition === undefined) {
            projectionVector.multiplyScalar(uploadDistance)
            projectedPosition = camera.position.clone().add(projectionVector)
        }

        dispatch(updateInitialPosition(projectedPosition))
        const newEditorStorage: EditorStorage = {
            lastOpenedAnchorType: ARAnchorType.WorldAnchor,
            lastOpenedObjectId: pointCloud._id,
            latestCameraData: {
                position: camera.position,
                quaternion: camera.quaternion,
            }
        }
        localStorage.setItem("graffity-editor-storage", JSON.stringify(newEditorStorage));
        setShouldGetPosition(false)
    }

    const arContentToDisplay = filterArContentToDisplay(arContents, errContentList, editorState, pointCloud)

    const poisToDisplay = filterPoiToDisplay(pois, editorState)

    const handleObjectUpdate = (object: THREE.Object3D, shouldUpdateServer: boolean) => {
        if (object.name.startsWith('poi-')) {
            return handlePoiUpdate(object, shouldUpdateServer)
        }
        handleArContentUpdate(object, shouldUpdateServer)
    }

    const handlePoiUpdate = (object: THREE.Object3D, shouldUpdateServer: boolean) => {
        const id = object.name.slice(4)
        const poiIndex = pois.findIndex((poi) => poi._id === id)
        const updatedPoi = { ...pois[poiIndex] }
        updatedPoi.position = {
            x: Math.round(100 * object.position.x) / 100,
            y: Math.round(100 * object.position.y) / 100,
            z: Math.round(100 * object.position.z) / 100,
        }
        if (shouldUpdateServer) {
            onPoiUpdate(poiIndex, updatedPoi)
            return
        }
        onPoiStateUpdate(poiIndex, updatedPoi)
    }

    const handleArContentUpdate = (object: THREE.Object3D, shouldUpdateServer: boolean) => {
        const arContentIndex = arContents.findIndex((arContent) => arContent._id === object.name)
        const updatedArContent = { ...arContents[arContentIndex] }
        updatedArContent.position = {
            x: Math.round(100 * object.position.x) / 100,
            y: Math.round(100 * object.position.y) / 100,
            z: Math.round(100 * object.position.z) / 100,
        }
        updatedArContent.rotation = {
            x: Math.round(100 * object.rotation.x * 180 / Math.PI) / 100,
            y: Math.round(100 * object.rotation.y * 180 / Math.PI) / 100,
            z: Math.round(100 * object.rotation.z * 180 / Math.PI) / 100,
        }
        updatedArContent.scale = {
            x: Math.round(100 * object.scale.x / scale) / 100,
            y: Math.round(100 * object.scale.y / scale) / 100,
            z: Math.round(100 * object.scale.z / scale) / 100,
        }
        if (shouldUpdateServer) {
            if (group !== null) {
                return updateGroupFromArContent(updatedArContent, true)
            }
            onArContentUpdate(arContentIndex, updatedArContent)
            return
        }
        if (group !== null) {
            return updateGroupFromArContent(updatedArContent)
        }
        onArContentStateUpdate(arContentIndex, updatedArContent)
    }

    const onObjectClick = (objectid: string, isMultiSelect?: boolean) => {
        if (!enableClick) return
        if (objectid === "") {
            return clearSelection()
        }
        if (isPoiName(objectid)) {
            return dispatch(changeSelectedPoi(objectid.slice(4)))
        }
        if (isMultiSelect) {
            return addObjectToSelect(objectid)
        }
        return selectObject(objectid)
    }

    const onObjectError = (arContentId: string, message: string) => {
        if (errContentList.has(arContentId) || editorState.errors.find(error => error.objectid === arContentId)) return
        const newError: EditorError = {
            type: EditorErrorType.ArContentError,
            objectid: arContentId,
            message,
        }
        // debugger
        const newErrorList = new Set(errContentList).add(arContentId)
        setErrContentList(newErrorList)
        const newErrorsState = [
            ...editorState.errors,
            newError,
        ]
        dispatch(updateError(newErrorsState))
    }

    const disableOnClick = () => setEnableClick(false);
    const enableOnClick = () => setEnableClick(true);

    return (
        <>
            <UsageTip />
            <FloorSelector
                floors={floors}
                selectedFloor={selectedFloor}
                onFloorSelect={handleFloorSelect}
            />
            <PointCloudOptions
                place={pointCloud}
                onColorModeToggle={(e) => setSplatMode(e)}
                colorModeEnabled={splatMode}
                mapOverlayEnabled={editorState.showMapOverlay}
                onMapOverlayToggle={(e) => {
                    dispatch(toggleMapOverlay(e))
                }}
            />
            <ErrorBoundary fallback={
                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <p className="text-center"><span className="text-danger">An error has occured, please try again later.</span> <br />
                        If the issue persisted, Please contact us.
                    </p>
                </div>
            }>
                <Suspense fallback={
                    <div className='loading'>
                        <Spinner type="grow" className="loading-spinner" color="primary" />
                        <h6 className='mt-4'>Loading Editors...</h6>
                    </div>
                }>
                    <Canvas
                        camera={{
                            fov: 75,
                            near: 0.1,
                            far: 1000,
                            position: isLastOpenedPointCloud && latestCameraData ? [
                                latestCameraData.position.x,
                                latestCameraData.position.y,
                                latestCameraData.position.z,
                            ] : [10, 5, 40],
                            quaternion: isLastOpenedPointCloud && latestCameraData ?
                                new THREE.Quaternion(
                                    latestCameraData.quaternion.w,
                                    latestCameraData.quaternion.x,
                                    latestCameraData.quaternion.y,
                                    latestCameraData.quaternion.y,
                                )
                                : undefined,
                        }}
                        onPointerMissed={() => {
                            onObjectClick('')
                        }}
                        onPointerUp={() => {
                            setShouldGetPosition(true)
                        }}
                        gl={{ antialias: true, toneMapping: THREE.NoToneMapping, localClippingEnabled: true }}
                    >
                        <color attach="background" args={['#000000']} />
                        <Tracking
                            shouldGetPosition={shouldGetPosition}
                            onPositionChange={handleCameraPositionChange}
                        />
                        <ambientLight intensity={3} />
                        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
                        <pointLight position={[-10, -10, -10]} />
                        <OrbitControls
                            makeDefault
                            enableDamping={false}
                            onEnd={() => enableOnClick()}
                            onChange={() => disableOnClick()}
                        />
                        {
                            splatMode ? !!pointCloud.publicSplatFile && <group rotation={[Math.PI, 0, 0]}>
                                <Splat alphaTest={0.1} src={pointCloud.publicSplatFile} />
                            </group>
                                : <PointCloud />
                        }
                        {
                            arContentToDisplay.map((arContent) => <ArContentModel
                                key={arContent._id}
                                arContent={arContent}
                                onObjectClick={onObjectClick}
                                onError={onObjectError}
                                scale={scale}
                                isSelected={isArContentSelected(arContent, editorState)}
                            />)
                        }
                        {
                            mapImage && editorState.showMapOverlay && <MapModel
                                place={pointCloud}
                                mapImage={mapImage}
                                scale={mapScale}
                                positionY={
                                    floors[0].yMin ||
                                    (pointCloud.originCoordinate ? -pointCloud.originCoordinate.displayOriginCoordinates[2] : -10)
                                }
                            />
                        }
                        {
                            poisToDisplay.map(poi => PoiModel(poi, onObjectClick))
                        }
                        <Controls
                            selectedObjectId={selectedSceneObjectId}
                            controlMode={controlMode}
                            onArContentChange={handleObjectUpdate}
                            onMouseDown={() => disableOnClick()}
                            onMouseUp={() => enableOnClick()}
                        />
                    </Canvas>
                </Suspense>
            </ErrorBoundary>
        </>
    )
}

PointCloudEditorComponent.displayName = "PointCloudEditor"

const mapStateToProps = (state: ApplicationState): StateProps => ({
    editorState: state.Editor,
})

const PointCloudEditor = connect(mapStateToProps)(PointCloudEditorComponent);

export { PointCloudEditor, filterArContentToDisplay, filterPoiToDisplay, getSelectedSceneObjectId, PointCloudOptions }
