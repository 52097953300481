import PropTypes from "prop-types";
import React from "react";
import { Alert, Row, Col, CardBody, Card, Button, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

import { withRouter } from "../../tsxParser/router-v6";

// actions
import { loginUser, socialLogin } from "../../store/actions";
import { RootState } from "../../store/reducers";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png";
import googleLogo from "../../assets/images/brands/google.png";
import microsoftLogo from "../../assets/images/brands/microsoft.png"

import "./authentication.scss"
import { PageMeta } from "components/Common/Header";

const Login = (props: any) => {
  const dispatch = useDispatch();

  // const auth = getAuth();
  // auth.useDeviceLanguage();

  // const provider = new GoogleAuthProvider();
  // provider.addScope('profile');
  // provider.addScope('email');

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      // console.log("values", values)
      dispatch(loginUser(values, props.navigate));
    }
  });

  const { error } = useSelector((state: RootState) => ({
    error: state.Login.error,
  }));

  const signIn = (type: string) => {
    dispatch(socialLogin(type, props.navigate));
  };

  const socialResponse = (type: string) => {
    signIn(type);
  };

  return (
    <React.Fragment>
      <PageMeta title="Login | Graffity Console" />
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-3 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        {/* <p>Sign in to continue to Graffity Console.</p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="100%"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2 text-center">
                    <div className="d-grid">
                      {/* <Link
                        to="#"
                        className="social-list-item bg-danger text-black border-danger"
                        onClick={e => {
                          e.preventDefault();
                          googleSignIn();
                        }}
                      >
                        <i className="mdi mdi-google" />
                      </Link> */}
                      <Link
                        to="#"
                        onClick={e => {
                          e.preventDefault();
                          socialResponse("google");
                        }}
                      >
                        <Button
                          color="light"
                          className="login-btn"
                          outline
                        >
                          <img src={googleLogo} width="24" alt="Google" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          Sign in with Google
                        </Button>
                      </Link>
                      <Link
                        to="#"
                        onClick={e => {
                          e.preventDefault();
                          socialResponse("microsoft");
                        }}
                      >
                        <Button
                          color="light"
                          className="login-btn"
                          outline
                        >
                          <img src={microsoftLogo} width="24" alt="Google" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          Sign in with Microsoft
                        </Button>
                      </Link>
                      {/* <GoogleLogin
                        clientId={google.CLIENT_ID}
                        cookiePolicy={'single_host_origin'}
                        onSuccess={googleResponse}
                        onFailure={(err: any) => {
                          console.error(err)
                        }}
                        render={(renderProps: any) => (
                          <Button
                            color="dark"
                            outline
                            onClick={renderProps.onClick}
                          >
                            <img src={googleLogo} width="24" alt="Google" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Sign in with Google
                          </Button>
                        )}
                      /> */}
                    </div>
                  </div>

                  <hr className="mx-2 mt-2 mb-3" />

                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p>
                  Don&#39;t have an account?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Sign up now{" "}
                  </Link>{" "}
                </p>
                <p>
                  <a target="_blank" href="https://graffity.tech/mappers-privacy-policy">Privacy Policy</a> | <a target="_blank" href="https://graffity.tech/terms-of-service">Terms of Service</a>
                </p>
                <p>
                  © {new Date().getFullYear()} Graffity Technologies Co., Ltd.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
